import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getMessage } from '../utils/api';
import { isAuthenticated } from '../utils/auth';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#ffffff',
  },
}));

export const Home: FC = () => {
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const classes = useStyles();

  const queryBackend = async () => {
      const message = await getMessage();
      setMessage(message);

  };

  return (
    <>
      {!message && !error && (
        <>
        <a className={classes.link} href="#" onClick={() => queryBackend()}>
          Click to make request to backend.
        </a>
        <a className={classes.link} href="/topic">
            Topic Classifier
        </a>
          <a className={classes.link} href="/feedback">
            Feedback
        </a>
        </>
      )}
      {message && (
        <p>
          <code>{message}</code>
        </p>
      )}
      {error && (
        <p>
          Error: <code>{error}</code>
        </p>
      )}
      <a className={classes.link} href="/admin">
        Admin Dashboard
      </a>
      <a className={classes.link} href="/protected">
        Protected Route
      </a>
      {isAuthenticated() ? (
        <a className={classes.link} href="/logout">
          Logout
        </a>
      ) : (
        <>
          <a className={classes.link} href="/login">
            Login
          </a>
          <a className={classes.link} href="/signup">
            Sign Up
          </a>
        </>
      )}
    </>
  );
};
