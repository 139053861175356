import React, {FC, useState} from 'react';
import {getTopic} from '../utils';

export const TopicClassifier: FC = () => {

    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [input] = useState<string>('');

    const queryBackend = async () => {

      const message = await getTopic(text);
      console.log(message);
      setMessage(message["result"]);

  };

    const handleChange = (e: { target: { value: any; }; }) => {
        console.log(e.target.value);
        setText(e.target.value);
    }

  return (
      <>
          <title>Topic -  Classifier</title>
         <input onChange={ handleChange } style={{fontSize: '20px'}} />
            <a href="#" onClick={() => queryBackend()} style={{ color: 'red' }}>
             Evaluate Topic.
        </a>
          <p>{message}</p>

      </>
)
};
