import React, {FC, useState} from 'react';
import {getMetadata,getMediaItem,MediaInfo,sendFeedbackCurriculum} from '../utils';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


export const Curriculum: FC = () => {

    const [message, setMessage] = useState<Array<string>>([]);
    const [selected, setSelected] = useState<Array<number>>([]);
    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [error, setError] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [text2, setText2] = useState<string>('');
    const [text3, setText3] = useState<string>('');
    const [bound, setBound] = useState<number>(5);
    const [input] = useState<string>('');
    const [submitted,setSubmitted] = useState<boolean>(false);
    const [load,setLoad] = useState<boolean>(false);
    const [init,setInit] = useState<boolean>(true);

    const [source, setSource] = useState<string>('bayern');

    const queryBackend = async (mediaInfo: MediaInfo, source: string) => {
      const message = await getMetadata(mediaInfo.title+ " "+mediaInfo.description, source);
      setMessage(Object.keys(message["metadata"][0]["curriculum"]));
      setKeywords(message["metadata"][0]["keywords"]);
      setLoad(true);
  };


    const loadItem = () => {
        setLoad(false);
        setInit(false);
        getMediaItem().then((mediaInfo) => {
            setText(mediaInfo.title);
            setText2(mediaInfo.description);
            setText3("");
            queryBackend(mediaInfo,source);
        }
        ).catch(err => {console.log(err)});
        setSelected([]);
        setSubmitted(false);
    };


    const sendFeedback = () => {
        console.log(keywords);
        sendFeedbackCurriculum(keywords,message,selected);
        setSubmitted(true);
    }


    const giveFeedback = (val: number) => () => {
        var updated=selected;
        if(selected.includes(val)){
            const index = selected.indexOf(val);
            updated.splice(index, 1);
            setSelected(updated);
        }
        else{
            updated.push(val);
            setSelected(updated);
        }
        setText3("Curriculum erhalten: " + selected.length + " ausgewählt");
        console.log(keywords);
    }

    const showMore = () => {
        setBound(10)
        console.log(selected);
    }
     const showLess = () => {
        setBound(5)
    }


    const handleChange = (e: { target: { value: any; }; }) => {
            console.log(e.target.value);
            setSource(e.target.value);
    }

  return (
      <>
          <title>Feedback Recorder</title>


          <div  style={{width:"800px",margin:"50px"}}>


              <FormControl fullWidth style={{margin:"50px"}}>
                <InputLabel id="demo-simple-select-label">Lehrplan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={source}
                  label="Lehrplan"
                  onChange={handleChange}
                >
                  <MenuItem value={"bayern"}>Bayern</MenuItem>
                  <MenuItem value={"rheinland"}>Rheinland-Pfalz</MenuItem>
                </Select>
              </FormControl>


                     {init == true ?
              <Button variant="outlined" style={{width:"120px"}} onClick={loadItem}>Metadaten Laden</Button> : null}
           <List >
               <ListItem button><span style={{width:"150px"}}> <b>Titel: </b> </span>{text}</ListItem>
               <ListItem button><span style={{width:"150px"}}> <b>Beschreibung: </b> </span>{text2}</ListItem>
           </List>

          <p style={{color:"green"}}><b>{text3}</b></p>
              {submitted == false ?
                  <div>
                  <Button onClick={sendFeedback} variant="outlined" style={{width: "120px"}}>Feedback senden</Button></div>:
                  <div>
                  <Button variant="outlined" style={{width:"120px"}} onClick={loadItem}>Neue Metadaten</Button>
                  <p style={{color:"green"}}><b>Feedback erfolgreich gesendet</b></p>
                  </div>
              }
           <Divider />
              {load ==true ?
          <List>
         {message.map(function(item, i){
            if(i<bound) {
                return <ListItem key={i} button>
                    <ListItemText  primary={item} />
                    <ListItemSecondaryAction>
                        <Checkbox
                        edge="end"
                        onChange={giveFeedback(i)}
                        checked={selected.indexOf(i) !== -1}
                        />
                    </ListItemSecondaryAction>
                </ListItem>;
            }
        })}
          </List> : null
              }
              {bound==5 ?
               <Button variant="outlined" style={{width:"120px"}} onClick={showMore}>Zeige Mehr</Button> :
               <Button variant="outlined" style={{width:"120px"}} onClick={showLess}>Zeige Weniger</Button>
              }
          </div>
      </>
)
};
