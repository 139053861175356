import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { Home, Login, SignUp, Protected, PrivateRoute, TopicClassifier, CurriculumMapper, Curriculum, Systematik} from './views';
import { Admin } from './admin';
import { logout } from './utils/auth';

const useStyles = makeStyles((theme) => ({
  app: {
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Routes: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Switch>
      <Route path="/admin">
        <Admin />
      </Route>

      <div className={classes.app}>
        <header className={classes.header}>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/topic" component={TopicClassifier} />
          <PrivateRoute path="/curriculum" component={Curriculum} />
          <PrivateRoute path="/systematik" component={Systematik} />
          <Route
            path="/logout"
            render={() => {
              logout();
              history.push('/');
              return null;
            }}
          />
          <PrivateRoute path="/protected" component={Protected} />
          <Route exact path="/" component={Home} />
        </header>
      </div>
    </Switch>
  );
};
