import { BACKEND_URL } from '../config';

export const getMessage = async () => {
  const response = await fetch(BACKEND_URL);

  const data = await response.json();

  if (data.message) {
    return data.message;
  }

  return Promise.reject('Failed to get message from backend');
};


export const getTopic = async (text: string) => {
    let request_data={"text":text};
    let response = await fetch(
        '/api/v1/topic/', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};


export const sendFeedbackCurriculum = async (keywords:Array<string>,classification:Array<string>,feedback:Array<number>) => {
    let request_data={"keywords":keywords,"classification":classification,"feedback":feedback};
    let response = await fetch(
        '/api/v1/feedback_curriculum_link/', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};


export const sendFeedbackSystematik = async (keywords:Array<string>,classification:Array<string>,feedback:Array<number>) => {
    let request_data={"keywords":keywords,"classification":classification,"feedback":feedback};
    let response = await fetch(
        '/api/v1/feedback_systematik/', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};


export const getCurriculumLinks = async (text: string) => {
    let request_data={"text":text};
    let response = await fetch(
        '/api/v1/curriculum/', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};




export type MediaInfo= {
  title: string;
  description: string;
  keywords: Array<string>
}

export const getMediaItem = async () : Promise<MediaInfo> => {
    const response = await fetch('/api/v1/media_item');
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }

  return response.json();
};

export const getMediaItemFromSource = async (text: string) : Promise<MediaInfo> => {
    let request_data={"source_id":text};
    let response = await fetch(
        '/api/v1/media_item_from_source', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};


export const getMetadata = async (text: string, source: string) => {
    let request_data={
      "fields": [
        "keywords","curriculum","systematik"
      ],
      "land": source,
      "metadata": [
        {
          "id": "string",
          "sodixId": "string",
          "title": "",
          "description": text,
          "keywords": [
            "string"
          ],
          "educationalLevels": [
            "string"
          ],
          "typeOfSchools": [
            "string"
          ],
          "classLevel": [
            "string"
          ],
          "disciplines": [
            {
              "id": "string",
              "subjectName": "string",
              "level": 0,
              "childSubjectMap": {}
            }
          ]
        }
      ]
    }
    let response = await fetch(
        '/api/v1/metadata/', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(request_data)
        }
    );
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
    return response.json();
};

